import "styles/pages/page-home.scss";
import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import WeatherWidget from "components/WeatherWidget/WeatherWidget";

const HomePage = ({ pageContext }) => {
	const pageHome = pageContext.pageContent;
	const heroSlider = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		draggable: false,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 1000,
		pauseOnHover: false,
	};

	const PlayIcon = () => {
		return (
			<img src={require("assets/icons/video-play.svg").default} alt="" />
		);
	};

	return (
		<Layout>
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<section className="home-hero">
				<Slider {...heroSlider}>
					<div>
						<div className="home-hero__single-bg home-hero__single-bg--1" />
					</div>
				</Slider>
				<div className="home-hero__text-container">
					<img
						src={require("assets/images/thailand-text.svg").default}
						alt=""
						className="home-hero__text-logo img-fluid"
					/>
					<h1 className="home-hero__title">{pageHome.heroTitle}</h1>
				</div>
				<div className="container">
					<WeatherWidget className="home-hero__widget" />
				</div>
			</section>
			<section className="home-video">
				<div className="container">
					<ReactPlayer
						width="100%"
						height="800px"
						url="https://www.youtube.com/watch?v=isMCJWZu2QE&ab_channel=AmazingThailand"
						light={
							require("assets/images/video-thumbnail.jpg").default
						}
						playIcon={<PlayIcon />}
						controls={true}
						playing={true}
						playsinline={true}
					/>
				</div>
			</section>
			<section className="home-discovery">
				<div className="container">
					<h2 className="section-title">{pageHome.exploreTitle}</h2>
					<div className="row">
						<div className="col-xl-5 col-lg-7 offset-xl-1">
							<div
								className="home-discovery__text"
								dangerouslySetInnerHTML={{
									__html: pageHome.exploreDesc,
								}}
							></div>
						</div>
						<div className="col-lg-5 offset-xl-1">
							<img
								src={pageHome.exploreImg1?.sourceUrl}
								alt=""
								className="img-fluid home-discovery__img home-discovery__img--1"
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-5">
							<img
								src={pageHome.exploreImg2.sourceUrl}
								alt=""
								className="img-fluid home-discovery__img home-discovery__img--2"
							/>
							<img
								src={pageHome.exploreImg4.sourceUrl}
								alt=""
								className="img-fluid home-discovery__img home-discovery__img--3"
							/>
						</div>
						<div className="col-md-7">
							<img
								src={pageHome.exploreImg3.sourceUrl}
								alt=""
								className="img-fluid home-discovery__img home-discovery__img--4"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="home-attractions">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-xl-6 col-md-5">
							<img
								src={
									require("assets/images/attractions-img-1.jpg")
										.default
								}
								alt=""
								className="img-fluid home-attractions__img"
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-md-1">
							<h2 className="section-title">
								{pageHome.attractionsTitle}
							</h2>
							<div
								className="home-attractions__text"
								dangerouslySetInnerHTML={{
									__html: pageHome.attractionsDesc,
								}}
							></div>
							<Link to="/rekreacja" className="button">
								Czytaj więcej
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="home-location">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-5">
							<h2 className="section-title section-title--white">
								{pageHome.locationTitle}
							</h2>
							<div className="home-location__padding-container">
								<div
									className="home-location__text"
									dangerouslySetInnerHTML={{
										__html: pageHome.locationDesc,
									}}
								></div>
								<Link to="/lokalizacja" className="button">
									Czytaj więcej
								</Link>
							</div>
						</div>
						<div className="col-md-6 offset-md-1">
							<img
								src={
									require("assets/images/geography-img-1.jpg")
										.default
								}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="home-news">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-lg-6 col-md-5 home-news__left-col">
							<img
								src={pageHome.newsImg1?.sourceUrl}
								alt=""
								className="img-fluid home-news__img home-news__img--1"
							/>
							<img
								src={pageHome.newsImg2.sourceUrl}
								alt=""
								className="img-fluid home-news__img home-news__img--2"
							/>
						</div>
						<div className="col-lg-6 col-md-7 home-news__right-col">
							<h2 className="section-title">
								{pageHome.newsTitle}
							</h2>
							<div className="home-news__padding-container">
								<div
									className="home-news__text"
									dangerouslySetInnerHTML={{
										__html: pageHome.newsDesc,
									}}
								></div>
								<Link
									to="/wiadomosci-podroznicze"
									className="button"
								>
									Czytaj więcej
								</Link>
							</div>
							<img
								src={pageHome.newsImg3?.sourceUrl}
								alt=""
								className="img-fluid home-news__img home-news__img--3"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default HomePage;
