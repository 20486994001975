import "./weather-widget.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";

import classNames from "classnames";

const WeatherWidget = ({ className }) => {
  const [weather, setWeather] = useState(null);
  const fetchWeather = async () => {
    const response = await axios.post(
      "https://api.openweathermap.org/data/2.5/group?id=1609348,1614295,1152633,1153671,1151254&appid=45ff52678ddf359e632415a749c5c955&units=metric"
    );
    setWeather(response);
  };
  const getIcon = (iconID) => {
    if (iconID >= 200 && iconID <= 232) {
      return "weather-storm.svg";
    } else if (iconID >= 300 && iconID <= 321) {
      return "weather-small-rain.svg";
    } else if (iconID >= 500 && iconID <= 532) {
      return "weather-big-rain.svg";
    } else if (iconID >= 701 && iconID <= 781) {
      return "weather-fog.svg";
    } else if (iconID === 800) {
      return "weather-sunny.svg";
    } else if (iconID === 801 || iconID === 802) {
      return "weather-small-clouds.svg";
    } else if (iconID === 803 || iconID === 804) {
      return "weather-big-clouds.svg";
    }
  };
  useEffect(() => {
    fetchWeather();
  }, []);

  const weatherSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <div className={classNames("weather-widget", className)}>
      <Slider {...weatherSlider}>
        {weather?.data.list.map((node, index) => {
          const iconID = node.weather[0].id;
          const icon = getIcon(iconID);

          return (
            <div key={index}>
              <div className="weather-widget__slide">
                <div className="weather-widget__text-container">
                  <div className="weather-widget__city">{node.name}</div>
                  <div className="weather-widget__temperature">
                    {node.main.temp.toFixed(0)}°
                  </div>
                </div>
                <img
                  src={require(`assets/icons/${icon}`).default}
                  alt=""
                  className="weather-widget__icon"
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default WeatherWidget;
